import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import Footer from '../footer/Footer';

import logo_TH from "./Icon_Lang_TH.svg";
import logo_EN from "./Icon_Lang_EN.svg";

// import i18n from 'i18n';

// import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import {
  Link
} from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
    //   marginRight: 36,
      display: 'none'
    },
    menuLink: {
      color: 'initial',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    //   backgroundColor: '#32465a'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        // width: theme.spacing(7) + 1,
        width: 0,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(9) + 1,
        // },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      height: '50px'
    //   ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      marginTop: '50px'
    },
    brand: {
        color: '#3e4855',
        fontFamily: 'FontTitle1',
        fontSize: '2em',
        lineHeight: '1em',
        fontWeight: 'bold',
        marginLeft: '-0.5em'
    },
    eachMenu: {
        // padding: 0
    },
    menuText: {
        // color: '#9cabba'
    },
    menuIcon: {
        // color: '#9cabba'
    }
  }),
);

export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { /*t,*/ i18n } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || ''

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <a href="https://dashboard.cmddi.go.th">
            <Typography variant="h6" noWrap className={classes.brand}>
              <span className="hidden-mobile">
                CHIANG MAI DISASTER DATA INTEGRATION
              </span>
              <span className="hidden-desktop">
                CMDDi
              </span>
            </Typography>
          </a>
          <div className="warp-change-language">
            <button onClick={() => changeLanguage('th')} className={`no-style ` + ((getCurrentLng() === 'th') ? 'active' : '')}>
              <img src={logo_TH} alt="th-logo"></img>
            </button>
            <button onClick={() => changeLanguage('en')} className={`no-style ` + ((getCurrentLng() === 'en') ? 'active' : '')}>
              <img src={logo_EN} alt="en-logo"></img>
            </button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>

        <Link to="/stations-history" className={classes.menuLink}>
          <ListItem button className={classes.eachMenu}>
            <ListItemIcon className={classes.menuIcon}>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={`ข้อมูลระดับน้ำย้อนหลัง`} className={classes.menuText} />
          </ListItem>
        </Link>

        </List>
      </Drawer>
      <main className={`main ${classes.content}`}>
        {/* <div className={classes.toolbar} /> */}
        {props.children}
      </main>

      <Footer></Footer>
      
    </div>
  );
}