import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class Radar extends Component {

    constructor(props) {
		super(props);
		this.state = {
			aomkoi_loop: 1
        }
    }

    componentDidMount() {
        setInterval(() => {
            if (this.state.aomkoi_loop === 6) {
                this.setState({
                    ...this.state,
                    aomkoi_loop: 1
                })
            } else {
                this.setState({
                    ...this.state,
                    aomkoi_loop: this.state.aomkoi_loop+1
                })
            }
        }, 1000)
    }

    render() {
        return (
            <div className="page-radar">
                <h1 className="main-title-page">
                    เรดาร์กลุ่มฝน
                </h1>
                <div className="wrap-each">
                    <h2>
                        อมก๋อย
                    </h2>
                    <img src={`https://weather.tmd.go.th/omk/omk${this.state.aomkoi_loop}.png`} alt="radar-aomkoi-loop" className="radar-img img-1" />
                    <div calssName="more-info">
                        <h5>
                            อ้างอิงข้อมูลจาก <a href="https://weather.tmd.go.th/omkLoop.php" target="_blank" rel="noopener noreferrer">https://weather.tmd.go.th/omkLoop.php</a>
                        </h5>
                    </div>
                </div>

                <hr></hr>

                <div className="wrap-each">
                    <h2>
                        ลำพูน
                    </h2>
                    <img src="https://weather.tmd.go.th/lmp/lmp240Loop.gif" alt="radar-lmp-lop" />
                    <div calssName="more-info">
                        <h5>
                            อ้างอิงข้อมูลจาก <a href="https://weather.tmd.go.th/lmpLoop.php" target="_blank" rel="noopener noreferrer">https://weather.tmd.go.th/lmpLoop.php</a>
                        </h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Radar);