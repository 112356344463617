import React, { Component } from 'react';
import { compose, withProps, withHandlers, withState } from "recompose"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mapStyles from "./style/wy";
// import mapStyles from "./style/ultra-light-with-labels";
// import mapStyles from "./style/becomeadinosaur";

import { getStations, setCurrentStation, getWaterData, getRainData, getStorageData, setWaterStationStatus, setRainStationStatus, setStorageStationStatus } from "./../actions/Station";

import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
    InfoWindow,
    KmlLayer,
    // MarkerClusterer,
    // OverlayView
} from "react-google-maps";

// import icon from './skateboarding.svg';
import iconDown from './../../assets/maps/icons/down.png';
import iconRed from './../../assets/maps/icons/red.png';
import iconGreen from './../../assets/maps/icons/green.png';
import iconOrange from './../../assets/maps/icons/orange.png';
import iconYellow from './../../assets/maps/icons/yellow.png';

import waterIconDown from './../../assets/maps/icons/water/down.png';
import waterIconRed from './../../assets/maps/icons/water/red.png';
import waterIconGreen from './../../assets/maps/icons/water/green.png';
import waterIconOrange from './../../assets/maps/icons/water/orange.png';
import waterIconYellow from './../../assets/maps/icons/water/yellow.png';

import storageIconDown from './../../assets/maps/icons/storage/down.png';
import storageIconRed from './../../assets/maps/icons/storage/red.png';
import storageIconGreen from './../../assets/maps/icons/storage/green.png';
import storageIconOrange from './../../assets/maps/icons/storage/orange.png';
import storageIconYellow from './../../assets/maps/icons/storage/yellow.png';

// import DangerIcon from './markers/DangerIcon';
import WaterInformation from '../waterInformation/WaterInformation';
import RainInformation from '../rainInformation/RainInformation';
import StorageInformation from '../storageInformation/StorageInformation';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
    return {
        stations: state.StationReducer.stations,
        currentStation: state.StationReducer.currentStation,
        currentDistrict:  state.StationReducer.currentDistrict,
        stationStatus: state.StationReducer.stationStatus
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getStations: getStations,
    setCurrentStation: setCurrentStation,
    getWaterData: getWaterData,
    getRainData: getRainData,
    getStorageData: getStorageData,
    setWaterStationStatus: setWaterStationStatus,
    setRainStationStatus: setRainStationStatus,
    setStorageStationStatus: setStorageStationStatus
}, dispatch)

const MapWrapped = compose(
    withProps({
        loadingElement: <div className="loadingElement" style={{ height: `100%` }} />,
        containerElement: <div className="containerElement" style={{ height: `100%` }} />,
        mapElement: <div className="mapElement" style={{ height: `100%` }} />,
    }),
    withState('zoom', 'onZoomChange', 8),
    withHandlers(() => {
        const refs = {
            map: undefined,
        }

        return {
            onMapMounted: () => ref => {
                refs.map = ref
            },
            onZoomChanged: ({ onZoomChanged }) => () => {
                onZoomChanged(refs.map.getZoom())
            }
        }
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={props.state.zoom}
        defaultCenter={props.state.center}
        defaultOptions={{ styles: mapStyles }}
        ref={props.onMapMounted}
        onZoomChanged={props.onZoomChanged}
        zoom={props.props.currentDistrict.zoom}
        center={props.props.currentDistrict.center}
    >
        {/* Always show kml */}
        {
            <KmlLayer
                key={`chaingmai-kml`}
                url={`${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/Bound_CM.kml`}
                // url={`${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/Bound_CM.kml?time=${props.now}`}
                options={{ preserveViewport: true, suppressInfoWindows: false }}
            />
        }

            {
                props.state.kml.map((value, i) => {
                    // console.log(props.props.currentDistrict.value + " " + value.name)
                    return (
                        props.props.currentDistrict.value === value.name &&
                            <KmlLayer
                                key={`${value.name}-${i}`}
                                url={value.path}
                                options={{ preserveViewport: true, suppressInfoWindows: false }}
                            />
                    )
                })
            }

{/* {
                props.state.kml.map((value, i) => {
                    return (
                        props.props.currentDistrict.value === '' &&
                            <KmlLayer
                                key={`${value.name}-${i}`}
                                url={value.path}
                                options={{ preserveViewport: true, suppressInfoWindows: false }}
                            />
                    )
                })
            } */}

            {/* {
                props.props.currentDistrict.value === '' &&
                    <KmlLayer
                        key={`chaingmai-kml`}
                        url={`${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/Bound_CM.kml`}
                        // url={`${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/Bound_CM.kml?time=${props.now}`}
                        options={{ preserveViewport: true, suppressInfoWindows: false }}
                    />
            } */}

            {
                props.props.currentStation &&
                    <InfoWindow
                        onCloseClick={() => {
                            props.setCurrentStation(null)
                        }}
                        position={{
                            lat: parseFloat(props.props.currentStation.latitude),
                            lng: parseFloat(props.props.currentStation.longitude)
                        }}
                    >
                        <div>
                            {
                                (props.props.currentStation.type === 'water') &&
                                    <div className="map-popup">
                                        <div className="title station-name">
                                            {props.props.currentStation.station} - {props.props.currentStation.station_name} ({props.props.currentStation.sub_district} {props.props.currentStation.district} {props.props.currentStation.province}) - สถานีน้ำท่า
                                        </div>

                                        <hr />

                                        <WaterInformation></WaterInformation>
                                    </div>
                            }

                            {
                                (props.props.currentStation.type === 'rain') &&
                                    <div className="map-popup">
                                        <div className="title station-name">
                                            {
                                                (props.props.currentStation.station_id) &&
                                                    <span>
                                                        {props.props.currentStation.station_id}
                                                    </span>

                                            }
                                            {props.props.currentStation.station} ({props.props.currentStation.sub_district} {props.props.currentStation.district} {props.props.currentStation.province}) - สถานีน้ำฝน
                                            
                                        </div>

                                        <hr />

                                        <RainInformation></RainInformation>
                                    </div>
                            }

                            {
                                (props.props.currentStation.type === 'storage') &&
                                    <div className="map-popup">
                                        <div className="title station-name">
                                            {props.props.currentStation.station} ({props.props.currentStation.sub_district} {props.props.currentStation.district} {props.props.currentStation.province}) - สถานีเขื่อน
                                        </div>

                                        <hr />

                                        <StorageInformation></StorageInformation>
                                    </div>
                            }
                        </div>
                    </InfoWindow>
            }


                {
                    // น้ำท่า
                    (props.props.stations.rain) &&
                        props.props.stations.water.map((value, index) => {
                            let warning_icon = waterIconGreen
                            if (value.current_warning_level === 2) {
                                warning_icon = waterIconYellow
                            } else if (value.current_warning_level === 3) {
                                warning_icon = waterIconOrange
                            } else if (value.current_warning_level === 4) {
                                warning_icon = waterIconRed
                            } 
                            if (value.status === 'down') {
                                warning_icon = waterIconDown
                            }
                            if ((value.current_warning_level !== null && value.current_warning_level !== 0 && value.current_warning_level !== 1) || props.props.stationStatus.water)
                                return (
                                    <Marker
                                        key={`station-water-${index}`}
                                        position={{
                                            lat: parseFloat(value.latitude),
                                            lng: parseFloat(value.longitude)
                                        }}
                                        icon={{
                                            url: warning_icon,
                                            scaledSize: new window.google.maps.Size(props.state.zoom*1.25, props.state.zoom*1.25)
                                        }}
                                        onClick={() => {
                                            props.setCurrentStation(value)
                                            // setSelectedPark(null);
                                        }}
                                    >
                                        
                                    </Marker>
                                )
                            return '';
                        })
                }
                
                    
                {
                    // น้ำฝน
                    (props.props.stations.rain) &&
                        props.props.stations.rain.map((value, index) => {
                            let warning_icon = iconGreen
                            if (value.current_warning_level === 2) {
                                warning_icon = iconYellow
                            } else if (value.current_warning_level === 3) {
                                warning_icon = iconOrange
                            } else if (value.current_warning_level === 4) {
                                warning_icon = iconRed
                            }
                            if (value.status === 'down') {
                                warning_icon = iconDown
                            }
                            if  ((value.current_warning_level !== null && value.current_warning_level !== 0 && value.current_warning_level !== 1) || props.props.stationStatus.rain)
                                return (
                                    <Marker
                                        key={`station-rain-${index}`}
                                        position={{
                                            lat: parseFloat(value.latitude),
                                            lng: parseFloat(value.longitude)
                                        }}
                                        icon={{
                                            url: warning_icon,
                                            scaledSize: new window.google.maps.Size(props.state.zoom, props.state.zoom)
                                        }}
                                        onClick={() => {
                                            props.setCurrentStation(value)
                                            // setSelectedPark(null);
                                        }}
                                    >
                                        
                                    </Marker>
                            )
                            return '';
                        })
                }
                
                {
                    // เขื่อน
                    (props.props.stations.storage) &&
                        props.props.stations.storage.map((value, index) => {
                            let warning_icon = storageIconGreen
                            if (value.current_warning_level === 2) {
                                warning_icon = storageIconYellow
                            } else if (value.current_warning_level === 3) {
                                warning_icon = storageIconOrange
                            } else if (value.current_warning_level === 4) {
                                warning_icon = storageIconRed
                            }
                            if (value.status === 'down') {
                                warning_icon = storageIconDown
                            }
                            if ((value.current_warning_level !== null && value.current_warning_level !== 0 && value.current_warning_level !== 1) || props.props.stationStatus.storage)
                                return (
                                    <Marker
                                        key={`station-storage-${index}`}
                                        position={{
                                            lat: parseFloat(value.latitude),
                                            lng: parseFloat(value.longitude)
                                        }}
                                        icon={{
                                            url: warning_icon,
                                            scaledSize: new window.google.maps.Size(props.state.zoom*1.625, props.state.zoom*1.625)
                                        }}
                                        onClick={() => {
                                            props.setCurrentStation(value)
                                            // setSelectedPark(null);
                                        }}
                                    >
                                        
                                    </Marker>
                            )
                            return '';
                        })
                }
                
            
    </GoogleMap>
)

class Map1 extends Component {
    static defaultProps = {
        now: new Date().getTime()
    };

    constructor(props) {
		super(props);
		this.state = {
            center: {
                lat: 18.78,
                lng: 98.97
            },
            zoom: 8.5,
            kml: [
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/ChaiPrakan.kml?time=${this.props.now}`,
                    name: 'ไชยปราการ'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/ChiangDao.kml?time=${this.props.now}`,
                    name: 'เชียงดาว'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/ChomThong.kml?time=${this.props.now}`,
                    name: 'จอมทอง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/DoiLo.kml?time=${this.props.now}`,
                    name: 'ดอยหล่อ'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/DoiSaket.kml?time=${this.props.now}`,
                    name: 'ดอยสะเก็ด'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/DoiTao.kml?time=${this.props.now}`,
                    name: 'ดอยเต่า'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/Fang.kml?time=${this.props.now}`,
                    name: 'ฝาง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/HangDong.kml?time=${this.props.now}`,
                    name: 'หางดง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/Hot.kml?time=${this.props.now}`,
                    name: 'ฮอด'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/Kanlayaniwatthana.kml?time=${this.props.now}`,
                    name: 'กัลยาณิวัฒนา'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/MaeAi.kml?time=${this.props.now}`,
                    name: 'แม่อาย'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/MaeChaem.kml?time=${this.props.now}`,
                    name: 'แม่แจ่ม'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/MaeOn.kml?time=${this.props.now}`,
                    name: 'แม่ออน'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/MaeRim.kml?time=${this.props.now}`,
                    name: 'แม่ริม'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/MaeTaeng.kml?time=${this.props.now}`,
                    name: 'แม่แตง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/MaeWang.kml?time=${this.props.now}`,
                    name: 'แม่วาง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/MueangChiangMai.kml?time=${this.props.now}`,
                    name: 'เมืองเชียงใหม่'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/Omkoi.kml?time=${this.props.now}`,
                    name: 'อมก๋อย'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/Phrao.kml?time=${this.props.now}`,
                    name: 'พร้าว'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/Samoeng.kml?time=${this.props.now}`,
                    name: 'สะเมิง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/SanKamphaeng.kml?time=${this.props.now}`,
                    name: 'สันกำแพง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/SanPaTong.kml?time=${this.props.now}`,
                    name: 'สันป่าตอง'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/SanSai.kml?time=${this.props.now}`,
                    name: 'สันทราย'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/Saraphi.kml?time=${this.props.now}`,
                    name: 'สารภี'
                },
                {
                    path: `${process.env.REACT_APP_MAIN_URL}/assets/maps/kml/map_Amp/WiangHaeng.kml?time=${this.props.now}`,
                    name: 'เวียงแหง'
                },
            ]
        }
    }

    componentDidMount() {
        this.props.getStations()
    }

    setCurrentStation = (obj) => {
        // console.log('-- set locate')
        // console.log(obj)
        this.props.setCurrentStation(obj)

        if (obj) {
            if (obj.type === 'rain') {
                this.props.getRainData(obj, 10)
            } else if (obj.type === 'water') {
                this.props.getWaterData(obj)
            } else if (obj.type === 'storage') {
                this.props.getStorageData(obj)
            }
        }
    }

    onZoomChanged = (zoom) => {
        // const zoomLevel = this.refs.map.getZoom();
        // console.log('current zoom ' + zoom)
        this.setState({
            ...this.state,
            zoom: zoom
        })

        

        if (zoom < 10) {
            this.props.stationStatus.water !== true && this.props.setWaterStationStatus(false)
            this.props.stationStatus.rain !== true && this.props.setRainStationStatus(false)
            this.props.stationStatus.storage !== true && this.props.setStorageStationStatus(false)
        } else {
            this.props.setWaterStationStatus(true)
            this.props.setRainStationStatus(true)
            this.props.setStorageStationStatus(true)
        }
        
    }

    showStationOption = (type, event) => {
        // console.log(type, event.target.checked)

        let value = event.target.checked

        if (type === 'water') {
            this.props.setWaterStationStatus(value)
        }

        if (type === 'rain') {
            this.props.setRainStationStatus(value)
        }

        if (type === 'storage') {
            this.props.setStorageStationStatus(value)
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div style={{height: '100%', width: '100%', position: 'relative'}}>
                <MapWrapped
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
                    state={this.state}
                    props={this.props}
                    env={process.env}
                    setCurrentStation={this.setCurrentStation}
                    // ref={this.refs}
                    onZoomChanged={this.onZoomChanged}
                />
                <div className="wrap-map-station-options">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="check-water" checked={this.props.stationStatus.water} onChange={this.showStationOption.bind(this, 'water')} />
                        <label className="form-check-label" for="check-water">
                            {t('map.stations.สถานีน้ำท่า')}
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="check-rain" checked={this.props.stationStatus.rain} onChange={this.showStationOption.bind(this, 'rain')} />
                        <label className="form-check-label" for="check-rain">
                            {t('map.stations.สถานีน้ำฝน')}
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="check-storage" checked={this.props.stationStatus.storage} onChange={this.showStationOption.bind(this, 'storage')} />
                        <label className="form-check-label" for="check-storage">
                            {t('map.stations.สถานีเขื่อน')}
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Map1);