import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class WarningWeather extends Component {
    render() {
        return (
            <div >
                <h1 className="main-title-page">
                    ประกาศเตือนภัย
                </h1>
                    <div calssName="" style={{textAlign: 'right',marginRight:'20%'}}>
                        {/* <h5>  อ้างอิงข้อมูลจาก <a href="https://www.tmd.go.th/list_warning.php" target="_blank" rel="noopener noreferrer">https://www.tmd.go.th/list_warning.php</a></h5> */}
                        <h5>  อ้างอิงข้อมูลจาก <a href="https://www.tmd.go.th/warning-and-events/warning-storm" target="_blank" rel="noopener noreferrer">https://www.tmd.go.th/warning-and-events/warning-storm</a></h5>
                    </div>
                    <div id="myiframe_position1"  >
                        <style jsx>{`
                                div#myiframe_position1{
                                    position:relative;
                                    margin:auto;
                                    display:block;  
                                    width:1200px; 
                                    height:1380px; 
                                    overflow:scroll;
                                }
                                div#myiframe_position1 iframe{
                                    position:absolute;
                                    display:block;
                                    float:left;
                                    margin-top:-184px;
                                    margin-left:120px; 
                                }

                        `}</style>
                        
                        {/* <iframe src="https://www.tmd.go.th/list_warning.php" title="list-warning" scrolling="no" frameborder="0" width="930" height="1380"></iframe> */}
                        <iframe src="https://www.tmd.go.th/warning-and-events/warning-storm" title="list-warning" scrolling="no" frameborder="0" width="930" height="1380"></iframe>
                       
                    
                        
                    </div>
            </div>
            
               
          
            
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WarningWeather);