import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { setCurrentDistrict } from '../actions/Station';

function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentDistrict: setCurrentDistrict
}, dispatch)

const options = [
    { value: '', label: '-- กรุณาเลือกอำเภอ --', center: {lat: 18.78,lng: 98.97}, zoom: 8 },
    { value: 'เมืองเชียงใหม่', label: 'เมืองเชียงใหม่', center: {lat: 18.789283,lng: 98.984971}, zoom: 12 },
    { value: 'จอมทอง', label: 'จอมทอง', center: {lat: 18.405751,lng: 98.592996}, zoom: 10 },
    { value: 'แม่แจ่ม', label: 'แม่แจ่ม', center: {lat: 18.584995,lng: 98.352548}, zoom: 10 },
    { value: 'เชียงดาว', label: 'เชียงดาว', center: {lat: 19.480175,lng: 98.990242}, zoom: 10 },
    { value: 'ดอยสะเก็ด', label: 'ดอยสะเก็ด', center: {lat: 18.929464,lng: 99.225530}, zoom: 11 },
    { value: 'แม่แตง', label: 'แม่แตง', center: {lat: 19.185189,lng: 98.860734}, zoom: 11 },
    { value: 'แม่ริม', label: 'แม่ริม', center: {lat: 18.940156,lng: 98.895742}, zoom: 11 },
    { value: 'สะเมิง', label: 'สะเมิง', center: {lat: 18.908117,lng: 98.660798}, zoom: 11 },
    { value: 'ฝาง', label: 'ฝาง', center: {lat: 19.891445,lng: 99.154245}, zoom: 11 },
    { value: 'แม่อาย', label: 'แม่อาย', center: {lat: 19.954132,lng: 99.338535}, zoom: 11 },
    { value: 'พร้าว', label: 'พร้าว', center: {lat: 19.310279,lng: 99.222952}, zoom: 10 },
    { value: 'สันป่าตอง', label: 'สันป่าตอง', center: {lat: 18.610310,lng: 98.883681}, zoom: 12 },
    { value: 'สันกำแพง', label: 'สันกำแพง', center: {lat: 18.743101,lng: 99.154585}, zoom: 12 },
    { value: 'สันทราย', label: 'สันทราย', center: {lat: 18.955884,lng: 99.029308}, zoom: 11 },
    { value: 'หางดง', label: 'หางดง', center: {lat: 18.729419,lng: 98.878981}, zoom: 11 },
    { value: 'ฮอด', label: 'ฮอด', center: {lat: 18.125406,lng: 98.440315}, zoom: 10 },
    { value: 'ดอยเต่า', label: 'ดอยเต่า', center: {lat: 17.920408,lng: 98.687298}, zoom: 11 },
    { value: 'อมก๋อย', label: 'อมก๋อย', center: {lat: 17.628901,lng: 98.323081}, zoom: 10 },
    { value: 'สารภี', label: 'สารภี', center: {lat: 18.696819,lng: 99.019208}, zoom: 12 },
    { value: 'เวียงแหง', label: 'เวียงแหง', center: {lat: 19.601443,lng: 98.664438}, zoom: 11 },
    { value: 'ไชยปราการ', label: 'ไชยปราการ', center: {lat: 19.679202,lng: 99.170290}, zoom: 11 },
    { value: 'แม่วาง', label: 'แม่วาง', center: {lat: 18.669396,lng: 98.652824}, zoom: 11 },
    { value: 'แม่ออน', label: 'แม่ออน', center: {lat: 18.741107,lng: 99.303128}, zoom: 11 },
    { value: 'ดอยหล่อ', label: 'ดอยหล่อ', center: {lat: 18.528534,lng: 98.762372}, zoom: 12 },
    { value: 'กัลยาณิวัฒนา', label: 'กัลยาณิวัฒนา', center: {lat: 18.996466,lng: 98.293692}, zoom: 11 }
  ]

class SelectDistrict extends Component {

    onSelectChange = (v) => {
        console.log('-- onSelectChange')
        console.log(v)
        this.props.setCurrentDistrict(v)
    }

    render() {
        return (
            <div className="select-district-map">
                <Select options={options} placeholder={`--กรุณาเลือกอำเภอ--`} onChange={this.onSelectChange} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectDistrict);