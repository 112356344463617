import { combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import LoadingReducer from './LoadingReducer'
import StationReducer from './StationReducer'
import AirMapReducer from './AirMapReducer'
import WeatherReducer from './WeatherReducer'
import NotificationReducer from './NotificationReducer'

export default combineReducers({
    LoadingReducer,
    loadingBar: loadingBarReducer,
    StationReducer,
    AirMapReducer,
    WeatherReducer,
    NotificationReducer
})