import moment from 'moment'

moment.locale('th', {
    months : 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤษจิกายน_ธันวาคม'.split('_'),
    monthsShort : 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย_ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split('_'),
    monthsParseExact : true,
    weekdays : 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัสบดี_ศุกร์_เสาร์'.split('_'),
    weekdaysShort : 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
    // weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    // weekdaysParseExact : true,
    // longDateFormat : {
    //     LT : 'HH:mm',
    //     LTS : 'HH:mm:ss',
    //     L : 'DD/MM/YYYY',
    //     LL : 'D MMMM YYYY',
    //     LLL : 'D MMMM YYYY HH:mm',
    //     LLLL : 'dddd D MMMM YYYY HH:mm'
    // },
    // calendar : {
    //     sameDay : '[Aujourd’hui à] LT',
    //     nextDay : '[Demain à] LT',
    //     nextWeek : 'dddd [à] LT',
    //     lastDay : '[Hier à] LT',
    //     lastWeek : 'dddd [dernier à] LT',
    //     sameElse : 'L'
    // },
    relativeTime : {
        future: "ใน %s",
        past:   "%s ที่แล้ว",
        s  : 'วินาที',
        ss : '%d วินาที',
        m:  "a นาที",
        mm: "%d นาที",
        h:  "an ชั่วโมง",
        hh: "%d ชั่วโมง",
        d:  "วัน",
        dd: "%d วัน",
        w:  "a สัปดาห์",
        ww: "%d สัปดาห์",
        M:  "เดือน",
        MM: "%d เดือน",
        y:  "ปี",
        yy: "%d ปี"
    }
    // dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    // ordinal : function (number) {
    //     return number + (number === 1 ? 'er' : 'e');
    // },
    // meridiemParse : /PD|MD/,
    // isPM : function (input) {
    //     return input.charAt(0) === 'M';
    // },
    // // In case the meridiem units are not separated around 12, then implement
    // // this function (look at locale/id.js for an example).
    // // meridiemHour : function (hour, meridiem) {
    // //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // // },
    // meridiem : function (hours, minutes, isLower) {
    //     return hours < 12 ? 'PD' : 'MD';
    // },
    // week : {
    //     dow : 1, // Monday is the first day of the week.
    //     doy : 4  // Used to determine first week of the year.
    // }
});

moment.locale('th')

export default moment