import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStations, setCurrentStation, getWaterData, getRainData, getStorageData } from "./../actions/Station";

function mapStateToProps(state) {
    return {
        // stations: state.StationReducer.stations,
        currentStation: state.StationReducer.currentStation,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getStations: getStations,
    setCurrentStation: setCurrentStation,
    getWaterData: getWaterData,
    getRainData: getRainData,
    getStorageData: getStorageData
}, dispatch)

class StationsBlock extends Component {

    constructor(props) {
		super(props);
		this.state = {
			data: [],
            data_filtered: [],
            search: ""
        }
    }
    
    componentDidMount() {
        let data = []
        this.props.getStations()
            .then((response) => {
                data = [...response.water, ...response.rain, ...response.storage]
                this.setState({
                    ...this.state,
                    data: data,
                    data_filtered: data
                })
            })
    }

    setCurrentStation = (obj) => {
        this.props.setCurrentStation(obj)

        if (obj) {
            if (obj.type === 'rain') {
                this.props.getRainData(obj)
            } else if (obj.type === 'water') {
                this.props.getWaterData(obj)
            } else if (obj.type === 'storage') {
                this.props.getStorageData(obj)
            }
        }
    }

    onSearchChange = (value) => {
        this.fastFilter(value.target.value)
    }

    fastFilter = (search) => {
        let stations = this.state.data
        let filtered = stations.filter(station => {
            return  (station.station && station.station.indexOf(search)) !== -1 ||
                    (station.address_str && station.address_str.indexOf(search)) !== -1 ||
                    (station.station_name && station.station_name.indexOf(search)) !== -1
        }) 

        this.setState({
            ...this.state,
            data_filtered: filtered,
            search: search
        })
    }

    render() {
        let currentStation = this.props.currentStation

        return (
            <div className="page-station-history-station-block">
                <div className="filter-block">
                    <input type="search" name="keyword" className="form-control" placeholder="ค้นหา..." onChange={this.onSearchChange.bind(this)} value={this.state.search} />
                </div>
                {
                    this.state.data_filtered.map((value, index) => {
                        // console.log(index, value)
                        if (index === 0 && !currentStation) {
                            this.setCurrentStation(value)
                        }
                        return (
                            <div key={index} onClick={() => { this.setCurrentStation(value) }}>
                                {
                                    value.type === 'water' &&
                                        <div className={`each-station ${currentStation && currentStation.type === 'water' && currentStation.id === value.id ? 'active' : ''}`} type="water">
                                            <div className="title">
                                                {value.station} {value.station_name} - สถานีน้ำท่า
                                            </div>
                                            <div className="sub-title">
                                                {value.address_str}
                                            </div>
                                        </div>
                                }

                                {
                                    value.type === 'rain' &&
                                        <div className={`each-station ${currentStation && currentStation.type === 'rain' && currentStation.id === value.id ? 'active' : ''}`} type="rain">
                                            <div className="title">
                                                {value.station} - สถานีน้ำฝน
                                            </div>
                                            <div className="sub-title">
                                                {value.address_str}
                                            </div>
                                        </div>
                                }

                                {
                                    value.type === 'storage' &&
                                        <div className={`each-station ${currentStation && currentStation.type === 'storage' && currentStation.id === value.id ? 'active' : ''}`} type="storage">
                                            <div className="title">
                                                {value.station} - สถานีเขื่อน
                                            </div>
                                            <div className="sub-title">
                                                {value.address_str}
                                            </div>
                                        </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StationsBlock);