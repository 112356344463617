import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip,
    AreaChart, 
    Area
} from 'recharts';

function mapStateToProps(state) {
    return {
        waterData: state.StationReducer.waterData,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload !== null && Array.isArray(payload) && payload[0]) {
        // console.log()
      return (
        <div className="custom-tooltip">
            {/* <p className="label">{`${label} : ${payload[0].value}`}</p>
            <p className="intro">{label}</p>
            <p className="desc">Anything you want can be displayed here.</p> */}
            <p>
                <strong>วันที่</strong> : {payload[0].payload.date} <br/>
                <strong>เวลา​</strong> : {payload[0].payload.time} <br/>
                <strong>ระดับน้ำ </strong>: {payload[0].payload.level} ม.<br/>
                <strong>อัตราการไหล</strong> :{payload[0].payload.dischg} ลบ.ม./ว
            </p>
        </div>
      );
    }
    return null;
};

class WaterInformation extends Component {
    render() {
        return (
            <div>
                <AreaChart width={500} height={250} data={this.props.waterData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>

                        <linearGradient id="colorLevel" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#0a6bb9" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#0a6bb9" stopOpacity={0.6}/>
                        </linearGradient>

                        <linearGradient id="colorLimit" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#f7464a" stopOpacity={0}/>
                            <stop offset="95%" stopColor="#f7464a" stopOpacity={0}/>
                        </linearGradient>

                    </defs>

                    <XAxis dataKey="time" />

                    <YAxis label={{ value: 'ระดับน้ำ (ม.)', angle: -90, position: 'insideLeft' }} />

                    <CartesianGrid strokeDasharray="3 3" />

                    {/* <Tooltip /> */}
                    <Tooltip content={<CustomTooltip />}/>

                    <Area type="monotone" dataKey="level" stroke="#0a6bb9" fillOpacity={1} fill="url(#colorLevel)" strokeWidth={2} />
                    <Area type="monotone" dataKey="level_limit" stroke="#f7464a" fillOpacity={1} fill="url(#colorLimit)" strokeWidth={2} />

                </AreaChart>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WaterInformation);