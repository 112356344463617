import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    XAxis, 
    YAxis, 
    CartesianGrid,
    Tooltip,
    ResponsiveContainer, 
    Brush,
    BarChart, 
    Bar
} from 'recharts';

function mapStateToProps(state) {
    return {
        rainData: state.StationReducer.rainData,
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload !== null && Array.isArray(payload) && payload[0]) {
        // console.log()
      return (
        <div className="custom-tooltip">
            {/* <p className="label">{`${label} : ${payload[0].value}`}</p>
            <p className="intro">{label}</p>
            <p className="desc">Anything you want can be displayed here.</p> */}
            <p>
                <strong>วันที่</strong> : {payload[0].payload.date} <br/>
                <strong>สถานี</strong> : {payload[0].payload.station} <br/>
                <strong>น้ำ</strong> : {payload[0].payload.water} <br/>
                <strong>ปริมาณน้ำฝน </strong>: {payload[0].payload.level_rain} มม.
            </p>
        </div>
      );
    }
    return null;
};

class CustomizedLabel extends Component {
  render() {
    const {
      x, y, stroke, value, width
    } = this.props;

    return <text x={x+(width/2)} y={y} dy={-4} fill={stroke} fontSize={16} textAnchor="middle" textAlign="center">{value}</text>  
  }
}

class RainInformation extends Component {
    render() {
        return (
            <div style={{width: '100%', height: '100%'}}>
                <ResponsiveContainer>
                <BarChart data={this.props.rainData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>

                        <linearGradient id="colorLevel" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#0a6bb9" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#0a6bb9" stopOpacity={0.6}/>
                        </linearGradient>

                        <linearGradient id="colorLimit1" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#f9ec7d" stopOpacity={0}/>
                            <stop offset="95%" stopColor="#f9ec7d" stopOpacity={0}/>
                        </linearGradient>
                        
                        <linearGradient id="colorLimit2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#fdb55c" stopOpacity={0}/>
                            <stop offset="95%" stopColor="#fdb55c" stopOpacity={0}/>
                        </linearGradient>

                        <linearGradient id="colorLimit3" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#f7464a" stopOpacity={0}/>
                            <stop offset="95%" stopColor="#f7464a" stopOpacity={0}/>
                        </linearGradient>

                    </defs>

                    <XAxis dataKey="date" />

                    <YAxis label={{ value: 'ปริมาณน้ำฝน (มม.)', angle: -90, position: 'insideLeft' }} />

                    <CartesianGrid strokeDasharray="3 3" />

                    <Tooltip content={<CustomTooltip />}/>

                    <Bar dataKey="level_rain" stroke="#0a6bb9" fillOpacity={1} fill="url(#colorLevel)" strokeWidth={2} label={<CustomizedLabel/>} />

                    {/* <Tooltip /> */}
                    
                    {/*<Area type="monotone" dataKey="level_rain" stroke="#0a6bb9" fillOpacity={1} fill="url(#colorLevel)" strokeWidth={2} />
                    <Area type="monotone" dataKey="limit_1" stroke="#f9ec7d" fillOpacity={1} fill="url(#colorLimit1)" strokeWidth={2} />
                    <Area type="monotone" dataKey="limit_2" stroke="#fdb55c" fillOpacity={1} fill="url(#colorLimit2)" strokeWidth={2} />
                    <Area type="monotone" dataKey="limit_3" stroke="#f7464a" fillOpacity={1} fill="url(#colorLimit3)" strokeWidth={2} />*/}
                    
                    <Brush dataKey="date" />

                </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RainInformation);