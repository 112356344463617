const initState = {
  warningStatus: []
}

const NotificationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_WARNING_STATUS':
      return {
        ...state,
        warningStatus: action.payload
      }
    default:
      return state
  }
}
  
export default NotificationReducer