const initState = {
  weathers: []
}

const WeatherReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_WEATHERS':
      return {
        ...state,
        weathers: action.payload
      }
    default:
      return state
  }
}
  
export default WeatherReducer