import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	// Redirect
	// Link
} from "react-router-dom";
// import Header from './components/header/Header';
// import Footer from './components/footer/Footer';
import './assets/scss/style.scss';
import Content2 from './components/content2/Content2';
import Content1 from './components/content1/Content1';
import AirMap from './components/AirMap/AirMap';
import SatelliteMap from './components/satelliteMap/SatelliteMap';
import Forecast7Day from './components/forecast7Day/Forecast7Day';
import Radar from './components/radar/Radar';
import MiniDrawer from './components/MiniDrawer/MiniDrawer';
import DailyWater from './components/dailyWater/DailyWater';
import ReservoirMeangud from './components/reservior/ReserviorMaengud';
import PageStationHistory from './components/pageStationHistory/PageStationHistory';
import WarningWeather from './components/warningWeather/WarningWeather';

function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

class App extends Component {

	constructor(props) {
		super(props);
		this.state = { 

		}
	}

	componentDidMount() {

	}

	
	render() {
		return (
			<Router>
				<div className="wrapper">
					<MiniDrawer>
						{/* <div className="main"> */}
							
								<Switch>
									<Route path="/air-map">
										<div className="content1">
										<AirMap></AirMap>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
									<Route path="/satellite-map">
										<div className="content1">
											<SatelliteMap></SatelliteMap>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
									<Route path="/forecast-7-day">
										<div className="content1">
											<Forecast7Day></Forecast7Day>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
									<Route path="/radar">
										<div className="content1">
											<Radar></Radar>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
									<Route path="/daily-water">
										<div className="content1">
											<DailyWater></DailyWater>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
									<Route path="/reservior">
										<div className="content1">
											<ReservoirMeangud></ReservoirMeangud>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
									<Route path="/stations-history">
										<PageStationHistory></PageStationHistory>
									</Route>
									<Route path="/warning">
										<div className="content1">
											<WarningWeather></WarningWeather>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
									<Route path="/">
										<div className="content1">
											<Content1></Content1>
										</div>
										<div className="content2">
											<Content2></Content2>
										</div>
									</Route>
								</Switch>
							
							
						{/* </div> */}
					</MiniDrawer>
					{/* <Footer></Footer> */}
				</div>
			</Router>
		);
	}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);