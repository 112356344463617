import React from 'react';

export function Step(props) {
    return (
        <div className="Stepper__step is-active">
            <div className="Stepper__indicator">
                <span className="Stepper__info">{props.indicator}</span>
            </div>
            <div className="Stepper__label">{props.title}</div>
            <div className="Stepper__panel">
                {props.children}
            </div>
        </div>
    )
}

export function Stepper(props) {
    return (
        <div className={`Stepper ${props.isVertical ? 'Stepper--vertical' : ''} ${props.isInline ? 'Stepper--inline' : ''}`}>
            {props.children}
        </div>
    )
}