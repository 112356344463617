import { showLoading, hideLoading } from 'react-redux-loading-bar'
const axios = require('axios');

export const setWeathers = (data) => ({
    type: 'SET_WEATHERS',
    payload: data
})

export function getWeather() {
    return (dispatch) => {
        dispatch(showLoading())
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cmmet-weather/get`)
            .then(function(result) {
                dispatch(setWeathers(result.data))
                dispatch(hideLoading())
                return result.data
            })
    }
}