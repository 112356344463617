import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class SatelliteMap extends Component {
    render() {
        return (
            <div style={{width: '100%', height: '100%'}}>
                
                <h1 className="main-title-page">
                    ภาพถ่ายดาวเทียม
                </h1>

                {/* <img src={`${process.env.REACT_APP_BACKEND_URL}api/req/getUrl?url=http://www.sattmet.tmd.go.th/satmet/asian/loop/enh/anigif.gif`} alt="ภาพถ่ายดาวเทียม" style={{width:'100%', marginBottom: '1em'}} /> */}
                <img src="http://www.sattmet.tmd.go.th/satmet/asian/loop/enh/anigif.gif" alt="ภาพถ่ายดาวเทียม" style={{width:'100%', marginBottom: '1em'}} />

                <div className="" style={{textAlign: 'right'}}>
                    <h5>อ้างอิงข้อมูลจาก 
                    <a href="http://www.sattmet.tmd.go.th/satmet/mergesat.html" target="blank" style={{marginLeft: '0.25em'}}>
                        http://www.sattmet.tmd.go.th/satmet/mergesat.html
                    </a></h5>
                </div>

                {/* <iframe src="http://www.sattmet.tmd.go.th/satmet/mergesat.html" style={{width: '100%', height: '100%'}}></iframe> */}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SatelliteMap);