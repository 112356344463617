const initState = {
  airMaps: []
}

const AirMapReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_AIRMAPS':
      return {
        ...state,
        airMaps: action.payload
      }
    default:
      return state
  }
}
  
export default AirMapReducer