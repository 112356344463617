import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class ReserviorMeangud extends Component {
    render() {
        return (
            <div>
                <h1 className="main-title-page">
                    ปริมาณน้ำในอ่างเก็บน้ำ
                </h1>
                
                <div style={{width: '100%', height: '100%'}} >
                  <img src={`${process.env.REACT_APP_BACKEND_URL}/api/req/getUrl?url=http://water.rid.go.th/flood/flood/res_table/05052008/p3.jpg`} alt="flood-img-1" />
                </div>
                <div style={{width: '100%', height: '100%'}} >
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/api/req/getUrl?url=http://water.rid.go.th/flood/flood/res_table/05052008/p4.jpg`} alt="flood-img-2" />
                </div>
                <div calssName="" style={{textAlign: 'right',marginRight: '9%',marginTop:'5%'}}>
                        <h5> อ้างอิงข้อมูลจาก <a href="http://water.rid.go.th" target="_blank" rel="noopener noreferrer">http://water.rid.go.th</a></h5>
                </div>
                
            </div>
            
               
          
            
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReserviorMeangud);